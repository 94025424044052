<template>
  <div>
    <p class="text-2xl mb-6">Données GA pour une liste d'URLs</p>
    <v-card class="mb-6 pa-2">
      <v-row class="ma-3">
        <v-col cols="12">
          <filters></filters>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import Filters from "@/components/c2c/gaUrlList/filters/Filters";
import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "GaUrlList",
  components: {
    Filters,
  },
  mounted() {
    saveRmraView(this.$route);
  },
  data() {
    return {};
  },
};
</script>
